<template>
  <productList ref="productList" :loading="loading" :finished="finished" @moreClick="moreClick"> </productList>
</template>

<script>
import productList from "@components/productList/index.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "pro",
  data() {
    return {
      dataList: [],
      finished: false,
      loading: false,
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
    };
  },
  components: {
    productList,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getProList();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
    }),
    //点击加载更多
    moreClick() {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getProList();
    },
    async getProList() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          productFollowQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  product{
                    id
                    name
                    banner
                    categoryEn
                    exhibitorId
                    isPopularize
                    panoramaUrl
                    exhibitor {
                      businesses(num: 3) {
                        isSample
                        remark
                      }
                      isLiving
                      category
                      boothInfo
                      company {
                        id
                        name
                        nameEn
                        logo
                        region
                        shortName
                        shortNameEn
                        countryEn
                        provinceEn
                        cityEn
                      }
                    }
                    likes{
                      userInfo
                    }
                    likeCount
                    selling
                    newestEvaluates{
                      content
                    }
                    video
                    view3DUrl
                    topicStart
                    topicEnd
                    topicType
                    region
                  }
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "userId",
          v: this.user.userId,
        },
        EqualB: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.productFollowQuery.query));
      let arr = [];
      res.items.forEach((e) => {
        arr.push(e.product);
      });
      res.items = arr;
      res.items.map((c) => {
        if (c.categoryEn && c.categoryEn.indexOf("[") !== -1) {
          if (JSON.parse(c.categoryEn)[0]) {
            c.categoryEn = JSON.parse(c.categoryEn)[0].Value;
          } else {
            c.categoryEn = "";
          }
        }
        if (c.likes && c.likes.length) {
          c.likes.forEach((e) => {
            e.userInfo = JSON.parse(e.userInfo);
          });
        }
        if (c.exhibitor.company) {
          c.exhibitor.company.regionTxt = `${c.exhibitor.company.countryEn ? c.exhibitor.company.countryEn + "." : ""}${c.exhibitor.company.provinceEn ? c.exhibitor.company.provinceEn : ""}${
            c.exhibitor.company.cityEn ? "." + c.exhibitor.company.cityEn : ""
          }`;
        }
      });
      if (this.pageIndex == 1) {
        this.dataList = res.items;
      } else {
        this.dataList = [...this.dataList, ...res.items];
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
      this.$nextTick(() => {
        this.$refs.productList.setData(this.dataList);
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
</style>

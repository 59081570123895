<template>
  <div class="content">
    <div class="m-card">
      <Tabs v-model="showTabs" @on-click="switchBrand">
        <TabPane :label="'Exhibitors（' + count.ex + '）'" name="1">
          <exhibitor v-if="String(showTabs) === '1'"></exhibitor>
        </TabPane>
        <TabPane :label="'Product（' + count.pro + '）'" name="2">
          <pro v-if="String(showTabs) === '2'"></pro>
        </TabPane>
        <TabPane :label="'Activity（' + count.act + '）'" name="3">
          <act v-if="String(showTabs) === '3'"></act>
        </TabPane>
      </Tabs>
    </div>
  </div>
</template>

<script>
import act from "./components/act/act";
import pro from "./components/act/pro";
import exhibitor from "./components/act/exhibitorList";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "layout",
  components: {
    act,
    pro,
    exhibitor,
  },
  data() {
    return {
      showTabs: "1",
      actTabs: "1",
      count: {
        ex: 0,
        pro: 0,
        act: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getCountWarp();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
    }),
    getCountWarp() {
      this.getCount("ex");
      this.getCount("pro");
      this.getCount("act");
    },
    async getCount(type) {
      let query = ``;
      let where = {
        Equal: {
          n: "userId",
          v: this.user.userId,
        },
        EqualB: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      if (type == "ex") {
        query = `
          query($query:QueryInput!){
            exhibitorFollowerQuery{
              query(query:$query){
                totalCount
              }
            }
          }
        `;
      } else if (type == "pro") {
        query = `
          query($query:QueryInput!){
            productFollowQuery{
              query(query:$query){
                totalCount
              }
            }
          }
        `;
      } else if (type == "act") {
        query = `
          query($query:QueryInput!){
            activityFollowQuery{
              query(query:$query){
                totalCount
              }
            }
          }
        `;
      }
      let opt = {
        query: query,
        variables: {
          query: {
            page: 10000,
            size: 1,
            where: JSON.stringify(where),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = {};
      if (type == "ex") {
        res = JSON.parse(JSON.stringify(data.data.exhibitorFollowerQuery.query));
      } else if (type == "pro") {
        res = JSON.parse(JSON.stringify(data.data.productFollowQuery.query));
      } else if (type == "act") {
        res = JSON.parse(JSON.stringify(data.data.activityFollowQuery.query));
      }
      if (res && res.totalCount) {
        this.count[type] = res.totalCount;
      }
    },
    switchBrand(name) {
      if (this.actTabs != this.showTabs) {
        this.actTabs = this.showTabs;
        this.getCountWarp();
      }
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  .m-card {
    min-height: 700px;
    border: solid 1px #ededed;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
</style>
